import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import axios from 'axios';
import {connect} from 'react-redux'
import {Row, Col, UncontrolledAlert, Badge, DropdownToggle, DropdownItem, DropdownMenu, Dropdown} from 'reactstrap';
import Card from 'antd/lib/card'

import * as actions from '../../../store'
import * as config  from '../../config/settings';

import TableStores from "./tablestores";
import {i18n} from "../../config/settings";
import Datepicker from "antd/lib/date-picker";
import Icon from "antd/lib/icon";
import moment from 'moment';

class Graph extends React.Component {

    chart = React.createRef()
    _url = `${config.URL}/webservice/statistics/pingdaily`

    state = {
        dropdownOpen: false,
        setDroprownOpen: false,
        date: moment().format('DD-MM-YYYY'),
        progress: 0,
        seconds: 0,
        options : {
                chart: {
                    plotBackgroundColor: null,
                    plotBorderWidth: 0,
                    plotShadow: false,
                    height: 300,
                    width: 300,
                },
                title: {
                    text: 'Ping<br>Status<br>',
                    align: 'center',
                    verticalAlign: 'middle',
                    y: 0
                },
                tooltip: {
                    pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
                },
                plotOptions: {
                    pie: {
                        dataLabels: {
                            enabled: true,
                            distance: -50,
                            style: {
                                fontWeight: 'bold',
                                color: 'white'
                            }
                        },
                        startAngle: 0,
                        endAngle: 360,
                        center: ['50%', '50%'],
                        size: '80%'
                    }
                },
                credits: {
                    enabled: false
                },
                series: [{
                    type: 'pie',
                    name: 'Ping Status',
                    innerSize: '60%',
                    data: [
                        ['Processed', 1307],
                        ['Not Processed', 5],
                        ['Pending', 3355],
                        ['To be processed', 0],
                        {
                            name: 'Other',
                            y: 7.61,
                            dataLabels: {
                                enabled: false
                            }
                        }
                    ]
                }]
        },
        tot_ping : 0
    }

    beat() {
        this.loadPieCharts();
        /*this.setState(prevState => ({
          second: prevState.second + 1,
        }))*/
    }

    componentDidMount() {
        this.loadPieCharts()
        this.interval = setInterval(() => this.beat(), 30000)
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    componentDidUpdate(prevProps) {
        if(prevProps.bellatrix_date !== this.props.bellatrix_date){
            this.loadPieCharts()
        }
    }



    toggleDropdown = () => this.setState({dropdownOpen: !this.state.dropdownOpen});


    handleChangeDate = (date) => {
        this.setState({date: moment(date).format('DD-MM-YYYY')})
        this.props.bellatrixSetDate(moment(date).format('YYYY-MM-DD'));
    }


    loadPieCharts = () => {

        let options = {...this.state.options }

        options.series[0].data = [];

        axios.post(this._url + '/', {date: this.props.bellatrix_date})
          .then(res => {
           res.data.series.map( (value,index) => {
             res.data.series[index] = [ i18n.t('translations:translations.graph.' + value[0] ), value[1]]
           });

           options.title.text = 'Ping Status<br><small>Tot ' + res.data.tot + ' </small>';
           options.series[0].data = res.data.series
           this.setState({options: options, loading_jobs: false, tot_ping: res.data.tot})
        })
    }


    render () {

        const extra_actions = (
            <div>
            <Datepicker onChange={this.handleChangeDate} defaultValue={moment()}  size={'small'}/>
            </div>
        )

        return (
            <Card className={"mb-3"} title="Ping status store" size="small" type={"inner"} extra={extra_actions}>
            <Row>
                <Col md={10} className={"p-3"}>
                   <Badge color={"danger"}>Danger</Badge> Ci sono 4 prodotti duplicati Lancia la procedura di pulizia duplicati
                </Col>
                <Col md={2} className={"text-right p-3"}>

                </Col>
                <Col md={4}>
                    <HighchartsReact
                    highcharts={Highcharts}
                    options={this.state.options}
                    ref={this.chart}/>
                </Col>
                <Col className={"d-flex align-items-center"} md={8}>
                    <TableStores tot_pings={this.state.tot_ping}/>
                    <div className={"pingtobeprocess"}></div>
                </Col>
            </Row>
            </Card>

        )
    }

}

const mapStateToProps = state => {
    return {
        bellatrix_date: state.bellatrix.date,
        bellatrix_id: state.bellatrix.id,
    }
}


const mapDispatchToProps = dispatch => {
    return {
        bellatrixSetId : (id) => dispatch(actions.bellatrixSetId(id)),
        bellatrixSetDate : (date) => dispatch(actions.bellatrixSetDate(date))
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(Graph)