import React, {Component} from 'react';
import {Container, Row, Col, Form, FormGroup, Input, Button, Badge} from 'reactstrap';
import {connect} from "react-redux";
import * as actions from '../../store'
import Switch from "antd/lib/switch";
import * as images from '../../assets/index'


class Header extends Component {

    state = {
        id: '',

    }

    componentDidMount(){
        console.log(this.props.bellatix_id)
    }

    handleChange = event => {
      this.setState({ id: event.target.value });

      if(this.timeout) clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
         this.props.bellatrixSetId(this.state.id);
      }, 700);
    }

    handleSubmit = event => {

        event.preventDefault();

       // this.props.bellatrix_id = this.state.id;
        return false
    }

    render(){
        return (
            <header className="app-header">

                <Container fluid>
                    <Row>
                        <Col xs={"1"} className={"pl-0 pr-0"}>
                            <a href={"/"} className={'logo'}>
                                <img src={images.logo} alt={"logo"} className={'img-responsive'}/>
                            </a>
                        </Col>
                        <Col xs={"9"}>
                            <Form onSubmit={this.handleSubmit}>
                                  <Row>
                                      <Col sm={"2"}>
                                          <FormGroup>
                                            <Input type="number" name="bellatrix_id"  value={this.state.id} id="bellatrix_id" placeholder="Inserire il bellatrix ID " onChange={this.handleChange} />
                                          </FormGroup>
                                      </Col>
                                  </Row>
                              </Form>
                        </Col>
                        <Col xs={"2"} className={"text-right"}>
                            <label>Produzione</label> <Switch size="small" defaultChecked />
                        </Col>
                    </Row>

                </Container>

            </header>
        )
    }
}



const mapStateToProps = state => {
    return {
        bellatrix_date: state.bellatrix.date,
        bellatrix_id: state.bellatrix.id,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        bellatrixSetId : (id) => dispatch(actions.bellatrixSetId(id)),
        bellatrixSetDate : (date) => dispatch(actions.bellatrixSetDate(date))
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(Header)
