import React from 'react';
import {Form, FormGroup, Button, Col, Input, Container, Row, Spinner } from 'reactstrap';
import axios from 'axios';
import Datepicker from 'antd/lib/date-picker';
import * as config  from '../config/settings';
import Card from 'antd/lib/card'


import ProductView from "./widgets/productview";
import Graph from "./widgets/graph";
import TablePing from "./widgets/tableping";
import Product from "./widgets/product";
import ServerStatus from "./widgets/serverstatus";

class BellatrixProduct extends React.Component {

    _isMounted = false;

    state = {
        id : '',
        jobs:[],
        key: 'tab1',
        noTitleKey: 'app',
    };



    componentDidMount() {
        this._isMounted = true;
    }

    componentDidUpdate(prevProps) {
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    onTabChange = (key, type) => {
        console.log(key, type);
        this.setState({ [type]: key });
    };

    render(){


    const tabListNoTitle = [
          {
            key: 'queu',
            tab: 'Last ping queue',
          },
          {
            key: 'filtered',
            tab: 'Filtered By Date',
          }
    ];

    const contentListNoTitle = {
      article: <p>article content</p>,
      app: <p>app content</p>,
      project: <p>project content</p>,
    };


    return (
          <Container fluid>
              <Row>
                 <Col>
                     <ServerStatus  />
                     <Card size={"small"} type={"inner"}
                      style={{ width: '100%' }}
                      tabList={tabListNoTitle}
                      activeTabKey={this.state.noTitleKey}
                      onTabChange={key => {
                        this.onTabChange(key, 'noTitleKey');
                      }}
                     >
                     <TablePing />
                     </Card>
                 </Col>
                 <Col className={"text-left"}>
                     <Graph />
                     <Product />

                 </Col>
              </Row>

          </Container>
        )
    }
}


export default BellatrixProduct