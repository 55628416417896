import React from 'react';
import ProductView from "./productview";
import axios from 'axios';
import {connect} from 'react-redux';

import * as actions from '../../../store'
import * as config  from '../../config/settings';

class Product extends React.Component {

    _isMounted = false

    state = {
        loading_jobs: true,
        item_rce:[],
        loading_item: false,
    }

    componentDidMount() {
        this._isMounted = true
    }

    componentWillUnmount() {
        this._isMounted = false
    }

    componentDidUpdate(prevProps) {
        if(this.props.bellatrix_id !== prevProps.bellatrix_id) {
            this.getProductInformation();
        }
    }

    getProductInformation = () => {

        this.setState({loading_jobs: true});

        let prodotto = []
        axios.get(`${config.URL}/product/${this.props.bellatrix_id}`)
              .then(res => {
                 // console.log(res.data);

                 if(res.data) {

                     res.data.map(row => {
                         prodotto.push(row)
                     })

                     if (prodotto !== undefined && this._isMounted) {
                         this.setState({item_rce: prodotto, loading_item: false});
                     }

                 }
        });

        return null;

    }

    render(){

         if(this.state.item_rce.length == 0){
            return (<div></div>)
         } else {
             return (
                 <div>
                     {this.state.item_rce.map(item => (
                         <ProductView item={item} bellatrix_id={this.props.bellatrix_id}/>
                     ))}
                 </div>
             )
         }
    }
}



const mapStateToProps = state => {
    return {
        bellatrix_date: state.bellatrix.date,
        bellatrix_id: state.bellatrix.id,
    }
}


const mapDispatchToProps = dispatch => {
    return {
        bellatrixSetId : (id) => dispatch(actions.bellatrixSetId(id)),
        bellatrixSetDate : (date) => dispatch(actions.bellatrixSetDate(date))
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(Product)
