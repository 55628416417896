import * as actionTypes from './actionTypes'

const _bellatrixSetId = (id) => {
    return {
        type : actionTypes.BELLATRIX_SET_ID,
        id: id
    }
}

const _bellatrixSetDate = (date) => {
    return {
        type : actionTypes.BELLATRIX_SET_DATE,
        date: date
    }
}

export const bellatrixSetId = (id) => {
    return dispatch => {
        dispatch(_bellatrixSetId(id))
    }
}

export const bellatrixSetDate = (date) => {
    return dispatch => {
        dispatch(_bellatrixSetDate(date))
    }
}



