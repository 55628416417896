import React from 'react';
import axios from 'axios';
import {connect} from 'react-redux'
import {Spinner} from 'reactstrap';
import {NavLink} from 'react-dom';
import Empty from 'antd/lib/empty'


import * as actions from '../../../store'
import * as config  from '../../config/settings';
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { selectFilter } from 'react-bootstrap-table2-filter';
import paginationFactory from 'react-bootstrap-table2-paginator';

class TablePing extends React.Component {

    state = {
        loading_jobs: true,
        seconds: 0
    }

    selectOptions = {
          'SHOP' : 'SHOP',
          'UPDATE': 'UPDATE',
          'DELETE': 'DELETE',
          'INSERT': 'INSERT',
          'BLOCKED': 'BLOCKED',
          'RESERVED': 'RESERVED',
          'TOSHIP': 'TOSHIP',
          'MATERIAL': 'MATERIAL',
          'PUBLISHED' : 'PUBLISHED',
          'SELL': 'SELL',
          'OPTIONATED' : 'OPTIONATED',
          'BLOCK' : 'BLOCK',
          'SHIPPED' : 'SHIPPED',
          'SYNC' : 'SYNC',
          'ONLINE' : 'ONLINE',
          'HIDE' : 'HIDE',
          'SELL_ONLINE' : 'SELL_ONLINE',
    };

    columns = [{
              dataField: 'id_action',
              text: 'ID JOB',
              sort: true,
         }, {
              dataField: 'post_id',
              text: 'ID post',
              sort: true,
         }, {
            dataField: 'sku',
            text: 'ID Bellatrix',
            events: {
                onClick: (e, column, columnIndex, row, rowIndex) => {
                    //console.log(e);
                    //console.log(column);
                    //console.log(columnIndex);

                    if(row.sku !== this.props.bellatrix_id) {
                        this.props.bellatrixSetId(row.sku)
                        this.setState({id: row.sku })
                    }

                    //console.log(rowIndex);
                    //alert('Click on Product ID field');
                },
            },
         },
         {
              dataField: 'view',
              text: 'Richiesta',
              sort: true,
         },
         {
              dataField: 'store',
              text: 'Store',
              formatter: this.highlightErrors,
              sort: true,
         },
         {
              dataField: 'action',
              text: 'Azioni',
              headerClasses: 'filter',
              sort: true,
              headerEvents: {
                onClick: (e, column, columnIndex) => {
                    //console.log(column.headerClasses);
                   column.headerClasses = (column.headerClasses == 'filter') ? 'filter open' : 'filter'
                    return column;
                }
              },
              //headerFormatter: this.headerFormatterFilterCols,
              formatter: cell => this.selectOptions[cell],
              filter: selectFilter({
                options: this.selectOptions
              })
         }, {
              dataField: 'priority',
              text: 'Priorita',
              sort: true,
         },
         {
            dataField: 'time_request',
            text: 'Ora Richiesta',
            sort: true,
         },
         {
              dataField: 'status_processing',
              text: 'Stato Richiesta',
              sort: true,
         },

         {
              dataField: 'time_processing',
              text: 'Ora Processo',
              sort: true,
         }];

        customTotal = (from, to, size) => (
          <span className="react-bootstrap-table-pagination-total">
            Showing { from } to { to } of { size } Results
          </span>
        );

        options = {
          paginationSize: 4,
          pageStartIndex: 1,
          // alwaysShowAllBtns: true, // Always show next and previous button
          // withFirstAndLast: false, // Hide the going to First and Last page button
          // hideSizePerPage: true, // Hide the sizePerPage dropdown always
          // hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
          firstPageText: 'First',
          prePageText: 'Back',
          nextPageText: 'Next',
          lastPageText: 'Last',
          nextPageTitle: 'First page',
          prePageTitle: 'Pre page',
          firstPageTitle: 'Next page',
          lastPageTitle: 'Last page',
          showTotal: true,
          paginationTotalRenderer: this.customTotal,
          sizePerPageList: [{
            text: '30', value: 30
          }, {
            text: '60', value: 60
          }] // A numeric array is also available. the purpose of above example is custom the text
        };


    headerFormatterFilterCols(column, colIndex){

        console.log(column);

        return (

            <div>{column.text} <i className={"icon-filter"} /></div>
        )
    }


    componentDidMount() {
     //   this.props.bellatrixSetId('54222');
        this.getListJobs()
        this.interval = setInterval(() => this.beat(), 30000)
    }

    beat(){
        this.getListJobs()
        this.setState(prevState => ({
            seconds: prevState.seconds + 1
        }))
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }


    highlightErrors(cell,row){
        if(row.action === 'SHOP' && row.post_status === 'publish'){
            return '<span className="error">{cell}</span>'
        } else {
            return cell
        }

    }

    componentDidUpdate(prevProps) {
      /*  if(prevProps.bellatrix_id !== this.props.bellatrix_id){
            this.getListJobs()
        }*/
    }


    getListJobs = () => {


        let jobs = []
        let url = (parseInt(this.props.bellatrix_id) > 0) ? `${config.URL}/webservice/${this.props.bellatrix_id}` : `${config.URL}/webservice/`

        axios.get(url)
          .then(res => {

            res.data.map(row => {
                jobs.push(row)
            })

            this.setState({jobs: jobs, loading_jobs: false});
        })

        return false
    }

    render() {



        return (
            this.state.loading_jobs ? (
                    <div>
                        <Spinner color="danger"/> Caricamento jobs in corso...
                        <Empty imageStyle={{
      height: 60,
    }}/>
                    </div>

                )
                : (<BootstrapTable
                    keyField='id_action'
                    wrapperClasses={'rce-table table-responsive'}
                    hover
                    data={this.state.jobs}
                    columns={this.columns}
                    pagination={ paginationFactory(this.options)}
                    filter={filterFactory()}
                />)
        )
    }
}


const mapStateToProps = state => {
    return {
        bellatrix_date: state.bellatrix.date,
     //   bellatrix_id: state.bellatrix.id,
    }
}


const mapDispatchToProps = dispatch => {
    return {
        bellatrixSetId : (id) => dispatch(actions.bellatrixSetId(id)),
        bellatrixSetDate : (date) => dispatch(actions.bellatrixSetDate(date))
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(TablePing)