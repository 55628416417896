import React, {useState} from 'react';
import { TabContent,
        Badge,
        Table, TabPane, Nav, NavItem, NavLink, Card, Button, CardTitle, CardText, Row, Col,
        Dropdown,DropdownMenu, DropdownItem, DropdownToggle } from 'reactstrap';
import classnames from 'classnames';

import axios from 'axios';
import TablePing from "./tableping";
import BellatrixApi from "./bellatixapi";

class ProductView extends React.Component {

    _isMounted = false;

    state = {
        active: '1',
        dropdownOpen: false,
        setDroprownOpen: false,
    }

    toggle = (val) => {
        this.setState({active : val})
    }

    toggleDropdown = () => this.setState({dropdownOpen: !this.state.dropdownOpen});

    render(){

        const item = this.props.item;


        const post_meta = Object.keys(item.post_meta).map(key =>
            <tr><td>{key}</td><td>{item.post_meta[key]}</td></tr>
        );

        const negozio = Object.keys(item.negozio).map(key =>
            <tr><td>{key}</td><td>{item.post_meta[key]}</td></tr>
        );
        console.log(post_meta);



        return (

            <div>
             <Nav tabs>
                <NavItem>
                  <NavLink
                    className={classnames({ active: this.state.active === '1' })}
                    onClick={() => { this.toggle('1'); }}
                  >
                    Dettagli Prodotto
                  </NavLink>
                </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: this.state.active === '2' })}
                onClick={() => { this.toggle('2'); }}
              >
             Post Meta
          </NavLink>
        </NavItem>
        <NavItem>
              <NavLink
                className={classnames({ active: this.state.active === '3' })}
                onClick={() => { this.toggle('3'); }}
              >
             Ping Prodotto
          </NavLink>
        </NavItem>
        <NavItem>
              <NavLink
                className={classnames({ active: this.state.active === '4' })}
                onClick={() => { this.toggle('4'); }}
              >
             Api Call Bellatrix
          </NavLink>
        </NavItem>
         <Dropdown nav isOpen={this.state.dropdownOpen} direction="left"  toggle={this.toggleDropdown} className="ml-auto">
          <DropdownToggle nav >
            <i className="icon-cog"/>
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem header>Header</DropdownItem>
            <DropdownItem disabled>Action</DropdownItem>
            <DropdownItem>Bellatrix API Call</DropdownItem>
            <DropdownItem divider />
            <DropdownItem>Another Action</DropdownItem>
          </DropdownMenu>
        </Dropdown>

      </Nav>
      <TabContent activeTab={this.state.active}>
        <TabPane tabId="1">
            <div className={"p-3"}>
              <Row>
                <Col sm="11">
                  <h4>{item.post_title}  </h4>
                </Col>
                <Col sm={1}>
                   <Badge>{item.post_status}</Badge>
                </Col>
              </Row>

              <Row>
                  <Col>
                     <img src={item.thumbnail} />
                  </Col>
                  <Col>
                    <p>
                        Bellatrix ID: <strong>{this.props.bellatrix_id}</strong><br />
                        Marchio: <strong>{item.brand}</strong><br />
                        Tipologia: <strong>{item.tipologia}</strong>
                    </p>

                   <ul>
                     <li>Prezzo: <strong>{item.prezzo}</strong></li>
                     <li>Stato Conservazione: <strong>{item.stato_conservazione}</strong></li>
                     <li>Garanzia: <strong>{item.garanzia}</strong></li>
                   </ul>


                  </Col>
              </Row>

               <Row>
                   <Col><NavLink href={item.url} target={'_blank'} className={'btn btn-primary'}>Link Prodotto sul sito</NavLink></Col>
                   <Col><NavLink href={`https://bellatrix.rcefoto.com/products/edit/${item.bellatrix_id}`} target={'_blank'} className={'btn btn-primary'}>Link Prodotto su Bellatrix</NavLink></Col>
               </Row>
            </div>
        </TabPane>
        <TabPane tabId="2">
            <div className={"p-3"}>
              <Row>
                <Col>
                    <div className={"table-responsive"}>
                    <Table className={"table rce-table table-striped table-hover table-bordered table-condensed"}>
                        <thead>
                        <tr>
                            <th>Meta Key</th>
                            <th>Meta Value</th>
                        </tr>
                        </thead>
                        <tbody>{post_meta}</tbody>
                    </Table>
                    </div>
                </Col>
              </Row>
            </div>
        </TabPane>
        <TabPane tabId="3">
            <div className={"p-3"}>
              <Row>
                <Col>
                    <TablePing bellatrix_id={this.props.bellatrix_id}/>
                </Col>
              </Row>
            </div>
        </TabPane>

      </TabContent>

          </div>
        )
    }
}

export default ProductView