import React from 'react';
import BellatrixProduct from "./components/bellatrix/bellatrixproduct";
import './components/scss/app.scss';
import Header from './components/common/header';

class App extends React.Component {

  bellatrix_id = 0;

  render() {
      return (
          <div className="app">
              <Header bellatrix_id={this.bellatrix_id}/>
              <section id={"section-page"}>
                  <BellatrixProduct bellatrix_id={this.bellatrix_id}/>
              </section>
          </div>
      )
  }
}

export default App;

