import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import resources from './translations'

i18next.use(LanguageDetector).init({
    resources,
    initImmediate: false,
    fallbackLng: 'en',
    ns: ['translatiosn'],
    defaultNS: 'translations',

    detection: {
        order: ['path','session','querystring','cookie','header'],
        load: 'languageOnly',
        lookupQuerystring: 'lng',
        lookupCookies:'i18n',
        lookupLocalStorage: 'i18nextLng',
        caches:['localStorage','cookie'],
        excludeCacheFor:['cimode'],
        cookieMinutes: 10,
        cookieDomain: '_rceck',
    },
    react: {
        bindI18n: 'languageChanged',
        bindI18nStore: '',
        transEmptyNodeValue: '',
        transSupportBasicHtmlNodes: true,
        transKeepBasicHtmlNodeFor: ['br','strong','i','b'],
        useSuspense: true,
    },
    interpolation: {
        escapeValue: false,
    }

})

export default i18next;