import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import {createStore, combineReducers,applyMiddleware, compose} from 'redux';
import thunk from 'redux-thunk';

import './components/scss/app.scss';
import BellatrixReducer from './store/reducers/bellatrix';
import App from './App';

import * as serviceWorker from './serviceWorker';

export const rootReducer = combineReducers({
    bellatrix: BellatrixReducer,
})

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(rootReducer, composeEnhancer(applyMiddleware(thunk)));

const app = (
    <Provider store={store}>
        <App />
    </Provider>
);

ReactDOM.render(app, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
