import React from 'react';
import ProductView from "./productview";
import axios from 'axios';
import {connect} from 'react-redux';

import * as actions from '../../../store'
import * as config  from '../../config/settings';

class BellatrixApi extends React.Component {


    render(){
        return (
            <div>
               API Bellatrix
            </div>
        )
    }
}

export default BellatrixApi