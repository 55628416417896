import * as actionTypes from '../actions/actionTypes'

const initialState = {
    id: {},
    date: {}
}

const reducer = (state = initialState, action) => {

    switch (action.type) {
        case actionTypes.BELLATRIX_SET_ID:
            return {
                ...state,
                id: action.id
            };

        case actionTypes.BELLATRIX_SET_DATE:
            return {
                ...state,
                date: action.date
            }

        default: return state;
    }
}

export default reducer;