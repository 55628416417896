import React from 'react';
import BootstrapTable from 'react-bootstrap-table-next';

class TableStores extends React.Component {

    columns = [{
          dataField: 'codice_negozio',
          text: 'Codice',
    },  {
          dataField: 'name',
          text: 'Negozio',

    }, {
          dataField: 'ping_tot_store',
          text: 'Tot Ping',
    },
    {
          dataField: 'ping_status_ok_store',
          text: 'Product Price',
    }];


    state = {
        stores: [
            {
                id: 1,
                codice_negozio: '001',
                name: 'RCE Foto - Padova, Riviera Tito Livio 32',
                ping_tot_store: 'test 1',
                ping_status_ok_store: 11
            },
            {
                id: 2,
                codice_negozio: '002',
                name: 'RCE Foto - Padova, Corso Milano, 110',
                ping_tot_store: 'test 2',
                ping_status_ok_store: 11
            },
            {
                id: 3,
                codice_negozio: '003',
                name: 'RCE Foto - Rovigo, Via Fuà Fusinato, 15',
                title: 'test 3',
                ping_status_ok_store: 11
            },
            {
                id: 4,
                codice_negozio: '004',
                name: 'RCE Foto - Verona, Corso Milano, 5',
                ping_tot_store: 'test 4',
                ping_status_ok_store: 11
            },
            {
                id: 5,
                codice_negozio: '005',
                name: 'RCE Foto - Vicenza, Stradella Dei Cappuccini, 3',
                title: 'test 5',
                ping_status_ok_store: 11
            },
            {
                id: 6,
                codice_negozio: '006',
                name: 'RCE Foto - Padova, Via Romana Aponense, 6',
                ping_tot_store: 'test 6',
                ping_status_ok_store: 11
            },
            {
                id: 7,
                codice_negozio: '007',
                name: 'RCE Foto - Verona - San Giovanni Lupatoto, Via Madonnina 48',
                ping_tot_store: 'test 7',
                ping_status_ok_store: 11
            },
            {
                id: 8,
                codice_negozio: '008',
                name: 'RCE Foto - Milano Lainate v.le Rimembranze 11',
                ping_tot_store: 'test 8',
                ping_status_ok_store: 11
            },
            {
                id: 9,
                codice_negozio: '',
                name: 'Totale Ping',
                ping_tot_store: this.props.tot_pings,
                ping_status_ok_store: 11
            }
        ]
    }

    render(){

        return (
            <BootstrapTable
              keyField="id"
              wrapperClasses={'table-stores'}
              data={ this.state.stores }
              columns={ this.columns }
              striped
              hover
              condensed
            />
        )

    }

}

export default TableStores;