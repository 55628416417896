import React from 'react';
import ProductView from "./productview";
import axios from 'axios';
import {connect} from 'react-redux';

import * as actions from '../../../store'
import * as config  from '../../config/settings';
import {Row, Col, Badge} from 'reactstrap'
import Progress from 'antd/lib/progress'
import Card from 'antd/lib/card'
import {i18n} from "../../config/settings";

import BootstrapTable from 'react-bootstrap-table-next'

class ServerStatus extends React.Component {

   _url = `${config.URL}/webservice/statistics/serverstatus`

   columns = [{
          dataField: 'service_name',
          text: 'Services',
    },{
          dataField: 'port',
          text: 'Port',
    },  {
          dataField: 'status',
          text: 'Status',

    }];

   state = {
       server_status: {
           load: {
                avg_1: "0.52",
                avg_2: "0.58",
                avg_3: "0.59"
            },
            uptime: " 8:43,   0 users",
            disk: [

            ],
            ram: {
                free_space: "",
                tot_space: "",
                free_percent: 0
            },
       },
       services: []
   }


       componentDidMount() {
     //   this.props.bellatrixSetId('54222');
     //   this.getListJobs()
           this.getServerStatus();
           this.interval = setInterval(() => this.beat(), 5000)
    }

        getServerStatus = () => {
             axios.post(this._url + '/', {date: this.props.bellatrix_date})
                  .then(res => {
                       this.setState({server_status: res.data.server_status, loading_jobs: false, services: res.data.services})
                  })
        }


    beat(){
     //   this.getListJobs()
        this.getServerStatus();
        this.setState(prevState => ({
            seconds: prevState.seconds + 1
        }))
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    render(){

        const extra_info = (
            <Row className={"uptime-info"}>
               <Col>Uptime {this.state.server_status.uptime}</Col>
            </Row>
        )

        return (
            <Card title="Server Status" size="small" type={"inner"} className={"mb-3"}  extra={extra_info}>
            <Row>
               <Col md={"8"}>
                 <Row>
                     <Col md={"4"}>Ram usage  <small>{this.state.server_status.ram.free_space}/{this.state.server_status.ram.tot_space}</small></Col>
                   <Col>
                       <Progress
                          strokeColor={{
                            from: '#108ee9',
                            to: '#87d068',
                          }}
                          percent={this.state.server_status.ram.free_percent}
                          status="active"
                        />
                   </Col>
                 </Row>

                   {this.state.server_status.disk.map((item,i) => (
                         <Row>
                           <Col  md={"4"}>Disk Free <Badge>{item.name}</Badge><small>{item.free_space}/{item.tot_space}</small></Col>
                           <Col>
                               <Progress
                                  strokeColor={{
                                    from: '#108ee9',
                                    to: '#87d068',
                                  }}
                                  percent={item.free_percent}
                                  status="active"
                                />
                           </Col>
                         </Row>
                       ))}
               </Col>
               <Col>
                 <Row>
                     <Col>Avg Load</Col>
                 </Row>
                 <Row>
                     <Col> <Badge >{this.state.server_status.load.avg_1}</Badge> <Badge >{this.state.server_status.load.avg_2}</Badge> <Badge >{this.state.server_status.load.avg_3}</Badge></Col>
                 </Row>
                   {/* <BootstrapTable
                      keyField="id"
                      wrapperClasses={'table-stores'}
                      data={ this.state.services }
                      columns={ this.columns }
                      striped
                      hover
                      condensed
                  /> */}
               </Col>
            </Row>
           </Card>
        )
    }
}

export default ServerStatus